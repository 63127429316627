import React, { memo } from 'react';
import './index.scss';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

export default memo(function dMenu({mode , paths}) {
    return (
        <Menu mode={mode} className='menu'>
            {paths.map(path => (
            <Menu.Item key={path.slug}>
                <NavLink to={path.route} activeClassName="active" className='link' exact>
                {path.slug === 'Services' ? `Our ${path.slug}` : path.slug}
                </NavLink>
            </Menu.Item>
            ))}
        </Menu>
    );
})
