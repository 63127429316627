import React, { memo, Suspense, lazy } from "react";
import "./index.scss";
import { Layout, Grid } from "antd";
import { CgMenuRightAlt } from 'react-icons/cg';

import Menu from '../../shared/Menu';

const Logo = lazy(() => import('./components/Logo'))

export default memo(function Header(props) {

  const { useBreakpoint } = Grid
  const breakpoint = useBreakpoint();
  const isMobileView = breakpoint.xs

  const onToggleMenu = () => props.setMobileDrawer((prevMobileDrawer) => !prevMobileDrawer)

  return (
    <Layout.Header className='header'>
      <Suspense fallback={<div></div>}>
        <Logo />
      </Suspense>
      {isMobileView ?
        <CgMenuRightAlt className='burger-menu' onClick={onToggleMenu} />
        : <Menu mode='horizontal' paths={props.paths} />
      }
    </Layout.Header >
  );
})
