import React, { useState, memo, lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, Grid } from 'antd';
import './App.scss';
import 'antd/dist/antd.css';

import Header from './shared/Header';

const Home = lazy(() => import('./Home'))
const AboutUs = lazy(() => import('./AboutUs'))
const Services = lazy(() => import('./Services'))
const ContactUs = lazy(() => import('./ContactUs'))

const NotFound = lazy(() => import('./shared/NotFound'))
const MobileDrawer = lazy(() => import('./shared/MobileDrawer'))
const Footer = lazy(() => import('./shared/Footer'))

const paths = [
  {
    exact: true,
    slug: 'Home',
    route: '/',
    component: Home,
  },
  {
    exact: true,
    slug: 'About Us',
    route: '/about-us',
    component: AboutUs,
  },
  {
    exact: true,
    slug: 'Services',
    route: '/services',
    component: Services,
  },
  {
    exact: true,
    slug: 'Contact Us',
    route: '/contact-us',
    component: ContactUs,
  }
];

export default withRouter(memo(function App(props) {

  const [isMobileDrawerOpen, setMobileDrawer] = useState(false);
  const mobileDrawer = { isMobileDrawerOpen, setMobileDrawer }

  const { useBreakpoint } = Grid
  const breakpoint = useBreakpoint();
  const isMobileView = breakpoint.xs

  return (
    <Layout className='layout'>
      <Header paths={paths} {...mobileDrawer} />
      {isMobileView && <Suspense fallback={<div></div>}>
        <MobileDrawer {...props} paths={paths} {...mobileDrawer} /></Suspense>}
      <Suspense fallback={<div style={{ minHeight: 'calc(100vh - 70px)' }}></div>}>
        <Switch>
          {window.scrollTo(0, 0)}
          {paths.map((path) => (
            <Route
              key={path.slug}
              exact={path.exact}
              path={path.route}
              component={path.component}
            />

          ))}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      <Suspense fallback={<div>Footer...</div>}>
        <Footer paths={paths} />
      </Suspense>
    </Layout>
  );
}));
